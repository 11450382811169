@import '../../../assets/styles/static/mixins';

.header {
  position: fixed;
  height: 88px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 7px 0;
  background-color: var(--white-obj);
  box-shadow: var(--box-shadow);
}

.container {
  max-width: 1460px;
  width: 100%;
  padding: 0 30px 0 10px;
  margin: 0 auto;
}

.inner {
  @include flex(space-between);
}

.left {
  .logoMini {
    display: none;
  }
  .logoBig {
    width: 202px;
    height: 69px;
    display: block;
  }
}

.middle {
  @include flex();
  padding: 0 10px;

  .search input {
    width: 320px;
    padding: 11px 15px 10px;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
  }

  .navigation {
    @include flex();
    margin-left: 40px;
    button:not(:first-child) {
      margin-left: 55px;
    }
  }
}

.right {
  @include flex();
  .user {
    @include flex();
    height: 34px;
    margin-right: 40px;
    margin-left: 40px;
    &__info {
      margin-left: 12px;
      height: 100%;
      @include flex(space-between, flex-start);
      flex-direction: column;
    }
    &__img {
      width: 34px;
      height: 34px;
      img {
        width: 100%;
        height: inherit;
        border-radius: 50%;
        border: var(--border);
      }
    }
    &__name {
      font-size: 14px;
      line-height: 16px;
      color: var(--blue);
    }
    &__logout {
      font-size: 12px;
      line-height: 14px;
      color: var(--gray-dark);
      cursor: pointer;
    }
  }
}

@include desktop {
  .middle {
    .search input {
      width: 280px;
    }
    .navigation {
      margin-left: 25px;
      button:not(:first-child) {
        margin-left: 30px;
      }
    }
  }
  .right .user {
    margin-right: 20px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 1050px) {
  .middle {
    .search input {
      width: 250px;
    }
    .navigation {
      margin-left: 20px;
      button:not(:first-child) {
        margin-left: 25px;
      }
    }
  }
}

@include desktop-sm {
  .left {
    .logoMini {
      display: block;
    }
    .logoBig {
      display: none;
    }
  }
}
