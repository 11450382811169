@import '../../../../assets/styles/static/mixins';

.form {
  margin-top: 15px;
  margin-bottom: 15px;
}

.field {
  margin-top: 15px;
  position: relative;
  input {
    background-color: var(--white-obj);
  }
  .name input,
  .name input::placeholder {
    color: var(--gray-dark);
  }
}

.info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
}
