* {
  box-sizing: border-box;
}

*::after,
*::before,
a,
p,
span,
section,
main,
header,
footer,
svg,
label {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--gray-dark);
}

h1,
h2,
h3 {
  color: var(--gray-dark);
}

h4,
h5 {
  font-family: var(--R_Light), sans-serif;
}

h1 {
  font-size: 24px;
  line-height: 28px;
}
h2 {
  font-size: 20px;
  line-height: 23px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 12px;
  line-height: 14px;
}

a {
  text-decoration: none;
  color: var(--black-obj);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
li,
label {
  word-break: break-word;
}

html {
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  color: var(--gray);
  background-color: var(--white-bg);
  padding: 0;
  margin: 0;
  font-family: var(--R_Regular), sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  overflow-x: hidden;
  min-width: 375px;
}

body.dark {
  --white-bg: var(--black-bg);
  --white-obj: var(--black-obj);
  --gray: #fff;
  --gray-dark: #fff;
  --gray-light: rgba(255, 255, 255, 0.55);
  --blocked: #525252;
  --selection: rgb(49 115 49);

  #dashboard {
    .menu-bg {
      background: linear-gradient(0deg, #878787, #878787), url('../../images/menu-bg.jpg');
      background-blend-mode: color, normal;
      opacity: 0.6;
      filter: blur(7.5px);
    }
  }
}

section {
  position: relative;
  overflow: visible;
}

img {
  width: 100%;
  object-fit: cover;
}

button {
  cursor: pointer;
  outline: none;
}

.container {
  position: relative;
  max-width: var(--container);
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  &--big {
    position: relative;
    max-width: var(--container-big);
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
  &--greatest {
    position: relative;
    max-width: var(--container-greatest);
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
}

.page-section {
  padding-top: 103px;
  padding-bottom: 50px;
}

.empty {
  margin-top: 45px;
  color: var(--blue);
  width: 100%;
  text-align: center;
  font-size: 40px;
  line-height: 47px;
  opacity: 0.3;
}

.regulations-btn {
  margin-bottom: 15px;
}

.htmlView {
  del {
    text-decoration: line-through;
  }

  ins {
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }

  strong {
    font-family: var(--R_Medium);
  }

  ul,
  ol {
    list-style: unset;
    margin: 16px 0;
  }

  li {
    display: list-item;
    margin-left: 1.5em;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  a {
    color: var(--blue);
    text-decoration: underline;
  }
}

.loading__wrapper {
  position: absolute;
  top: calc(50vh - 103px);
  left: 0;
  right: 0;
}

.change-lang {
  position: absolute;
  top: 28px;
  right: 30px;
  .css-1io5ltq-singleValue {
    color: #707070;
  }
}

.public-DraftStyleDefault-block {
  margin: 5px 0;
}

.rdw-link-decorator-wrapper {
  a {
    color: var(--blue);
    text-decoration: underline;
  }
  .rdw-link-decorator-icon {
    opacity: 0;
    width: inherit;
  }
}
.rdw-image-alignment-options-popup {
  color: var(--black-bg);
}
.rdw-fontsize-option {
  white-space: nowrap;
}

.spec-select {
  margin: 0 18px;
  width: fit-content;
  .select__control {
    border: 1px solid rgba(215, 230, 239, 1) !important;
    box-shadow: none !important;
    background-color: var(--white-obj);
    min-height: 44px;
    height: 44px;
    padding: 0;
  }
  .select__value-container {
    height: 44px;
    padding: 0 10px;
  }
  .select__indicator {
    padding-right: 12px;
  }
  .select__placeholder {
    color: var(--gray-light);
  }
  .select__menu {
    width: max-content;
  }
}
