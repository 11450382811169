#login {
	height: 100vh;
	background-color: #F5F5F5;
	@include flex();
	
	.login__inner {
		box-shadow: var(--box-shadow);
		border-radius: var(--border-radius);
		background-color: #FFFFFF;
		max-width: 437px;
		width: 100%;
		text-align: center;
		margin: 0 auto;
		padding: 50px 76px;
	}
}