.content {
  max-width: 1000px;
  padding: 30px 25px;
}

.body {
  padding: 15px 5px 0;
}

.changes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.changes__wrappper {
  position: relative;
}

.separator {
  width: 100%;
  height: 1px;
  background: rgba(215, 230, 239, 1);
  margin: 24px 0;
}

.block {
  width: 430px;
  height: 100%;
}

.value {
  padding: 15px;
  box-shadow: 0px 0px 4px 0px rgba(40, 130, 181, 0.4);
  border-radius: var(--border-radius);
}

.list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.item:not(:last-child) {
  margin-bottom: 8px;
}

.header {
  font-family: var(--R_Medium), sans-serif;
  color: var(--gray-dark);
}

.before,
.after {
  text-align: center;
  margin-top: 12px;
}

.before {
  color: rgba(235, 87, 87, 1);
}

.after {
  color: rgba(42, 157, 24, 1);
}

.arrow {
  width: 21px;
  height: 17.45px;
  margin: -31px 15px 0;
}

.prices,
.title {
  margin-bottom: 10px;
}

.clear {
  margin-top: 0 !important;
}

.greenBorder {
  padding: 4px;
  border: 3px solid var(--selection);
  border-radius: var(--border-radius);
}
