#data-logging {
  padding-top: 135px;
  padding-bottom: 50px;

  .data-logging__breadcrumbs {
    margin: 24px;
    justify-content: center;
  }
  .data-logging__filters {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: var(--white-obj);
    border: 1px solid rgba(215, 230, 239, 1);
    min-width: 1250px;
  }
  .data-logging__filter {
    input {
      color: var(--gray-light);
    }
    input,
    .select__control {
      border: 1px solid rgba(215, 230, 239, 1);
      box-shadow: none;
      background-color: var(--white-obj);
      min-height: 34px;
      height: 34px;
    }
    .select__control {
      padding: 0;
    }
    .select__value-container,
    .select__indicators {
      height: 32px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .select__input-container {
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
    .select__placeholder {
      color: var(--gray-light);
    }
  }
  .datepicker-input--start {
    margin-right: 11px;
  }
  .datepicker-input--end {
    margin-right: 24px;
  }
  .separator-arrow {
    transform: rotate(-90deg);
    margin-right: 11px;
    width: 11px;
    height: 5px;
    path {
      stroke: var(--gray-dark);
    }
  }
  .data-logging__table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .data-logging__table {
    margin-top: -1px;
    min-width: 1250px;
  }
  .data-logging__pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
  .data-logging__filter-wrapper {
    position: relative;
    width: 100%;
    max-width: 220px;
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
  .close-btn {
    position: absolute;
    right: 31px;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    display: none;
    cursor: pointer;
    svg {
      width: 10px;
      height: 10px;
    }
  }
  .close-btn.active {
    display: block;
  }
}
